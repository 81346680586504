<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
             {{ lista_campos != "" ? "Editar |" : "Criar |" }} parceiro
          </h3>
        </div>
        <div class="card-body table-responsive">
          <form
            action=""
            @submit.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
          >
            <div class="form-group row">
              <div class="col-md-5">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o Nome do Parceiro"
                />
              </div>
     
             
            </div>
            <div class="form-group row pt-1">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a primeira descrição do parceiro"
                />
              </div>
         
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-primary" :disabled="verif">
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: null,
        descricao: null,
      },
      variableWithMask: "",

      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_eventos(){
      return this.$store.state.evento.lista_eventos.map((cat) => ({
        id: cat.id,
        label: cat.nome_evento +' - '+ cat.id,
      }));
  },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.parceiros.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um parceiro no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;

      await this.$store.dispatch("parceiros/create_parceiro", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "parceiros",
      });
    },
    async update() {
 
      this.verif = true;
      await this.$store.dispatch("parceiros/update_parceiro", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "parceiros",
      });
    },
 async   preenxerCampos() {
      await this.$store.dispatch("evento/listar_eventos");

      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          descricao: this.lista_campos.descricao,
        };
    },

  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>